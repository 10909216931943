import "./bootstrap";
import "../css/app.css";
import "../css/layout.css";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ConfigProvider, theme, Typography } from 'antd'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const appName =  "Empower Snapshot";
const queryClient = new QueryClient();
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#4C4DDC",
                        colorWarning: "#f9896b",
                        colorBgLayout: "#fff",
                        borderRadius:10,
                        boxShadowTertiary:0,
                        boxShadow:0,
                        boxShadowSecondary:0,
                        fontFamily: "'Inter', sans-serif",

                    },
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <App {...props} />
                </QueryClientProvider>
            </ConfigProvider>
        );
    },
    progress: {
        color: "#4B5563",
    },
});
